/* CSS Document */

body {
	background: #f5f5f5;
}

.inner {
	max-width: 1200px;
	margin: 0 auto;
}

@media (max-width:1232px) {
	.inner {
		padding-left: 16px;
		padding-right: 16px;
	}
}

@media (max-width:768px) {
	.panel-body{
		padding : 8px;
	}
}

#release-notification{
	.modal-header{
		.ico-close{
			color: #fff;
			opacity: 1;
		}
	}
	@media (min-width: 768px){
		.modal-dialog{
			width : 720px;
		}
	}
	.modal-content{
		h4{
			color:#fff;
		}
		.list-group{
			font-size: 1.5em;
			.released_at{
				font-size:0.8em;
				margin-right : 12px;
			}
			.release_user_name{
				font-weight: bold;
			}
			.title{
				font-weight: bold;
			}
		}
		background: #D50000;
		animation: bg_blink 400ms ease 0s infinite alternate;
		
		.reload-text{
			background: #fff;
		}
	}
}

@-webkit-keyframes bg_blink {
	0% {background-color: #FF8A80;}
	100% {background-color: #D50000;}
}
@-moz-keyframes bg_blink {
	0% {background-color: #FF8A80;}
	100% {background-color: #D50000;}
}
@-ms-keyframes bg_blink {
	0% {background-color: #FF8A80;}
	100% {background-color: #D50000;}
}
@-o-keyframes bg_blink {
	0% {background-color: #FF8A80;}
	100% {background-color: #D50000;}
}
@keyframes bg_blink {
	0% {background-color: #FF8A80;}
	100% {background-color: #D50000;}
}

.panel-no-margin {
	margin-bottom : 0;
}

.pager-wrap{
	text-align: center;
	padding: 12px;
}

.pagination{
	margin: 0 auto;
}

.program-cell {
	padding-left : 40px;
	background-position: 8px center;
	background-size: 24px auto;
	background-repeat: no-repeat;
	.update-date{
		color:#999;
	}
	.program-time{
		width : 132px;
	}
}

.program-cell-released {
	background-image:url("../img/common/ico-released.png");
}

.program-cell-unreleased {
	background-image:url("../img/common/ico-unreleased.png");
}

nav.navbar {
	width: 100%;
	// background: #ddd;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;
}

.content_container {
	margin-top: 72px;
}

@media screen and (min-width:768px) {
	.nav_link_tab {
		background: #ccc;
		// height: 45px;
		// border-radius: 10px 10px 0 0 / 10px 10px 0 0;
		// -webkit-border-radius: 10px 10px 0 0 / 10px 10px 0 0;
		// -moz-border-radius: 10px 10px 0 0 / 10px 10px 0 0;
		// top: 6px;

		& + .nav_link_tab {
			// border-left: solid 1px #fff;
		}

		a {
			color: #fff !important;
		}
	}
}

@media screen and (max-width: 768px) {
	nav.navbar .container .navbar-collapse ul li a {
		text-align: left;
	}
}


// cursor: not-allowedは表示させない
.form-control[disabled] {
	cursor: default !important;
}